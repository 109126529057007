main {
    display: flex;
    flex-direction: column;
}

.bg_white {
    background-color: var(--white);
    background-size: cover;
    display: flex;
}

.bg_blue{
    background-color: var(--richBlack);
    background-size: cover;
    display: flex;
}

.space {
    background-color: transparent;
    color: var(--white);
    font-size: 1.7094rem;
    font-weight: var(--black);
    text-transform: uppercase;
    text-align: center;
    margin-right: 30px;
    margin-left: 30px;
}

.space h1{
    margin-top: 0;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
}

.space img{
    width: 80%;
}

h2{
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 7.5%;
    font-weight: var(--blackFont);
}

.intro{
    color: var(--brandeisBlue);
    font-size: 1.625rem;
    font-weight: var(--bold);
}

.intro p{
    margin: 0;
}

.about{
    display: block;
    padding: 11.112% 5.556%;
}

.history {
    color: var(--white);
    text-align: center;
    padding: 13.334% 6.667%;;
}


.history p{
    text-align: justify;
    margin: 0;
}

.cards_about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5.55556dvw;
}

.card_about{
    color: var(--white);
    padding: 10.626% 7.5%;
}

.card_about h3{
    font-weight: var(--blackFont);
    margin: 0;
    margin-bottom: 3.68%;
    font-size: 1.25rem;
}

.card_about p{
    margin: 0;
}

.card1{
    background: linear-gradient(180deg, #06F 0%, #0047FF 100%);
    border-radius: 24px;
}

.card2{
    background: linear-gradient(180deg, #FFB800 0%, #F90 100%);
    border-radius: 24px;
}

.card3{
    background: linear-gradient(180deg, #37C034 0%, #267E24 100%);
    border-radius: 24px;
}

.founders{
    text-align: center;
    color: var(--white);
}

.cards_founders{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5.55556dvw;
}

.cards_founders h3{
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 1.25rem;
}

.cards_founders p{
    margin:0;
    font-size: 0.875rem;
}

.cards_founders img {
    width: 75%;
}

.timeline {
    color: var(--brandeisBlue);
}

.timeline img{
    width: 100%;
}

@media screen and (min-width: 768px) {
    .space img{
        width: 20%;
    }
    .card_about{
        width: 70%;
    }
    .cards_founders article{
        width: 46.8%;
    }
    .timeline img{
        width: 80%;
    }
}

@media screen and (min-width: 1200px) {
    h2{
        margin-bottom: calc(7.5%/2);
    }
    .about{
        padding: calc(11.112%/2) 12dvw;
    }
    .space img{
        width: 20%;
    }
    .history {
        padding: calc(13.334%/2) 12dvw;;
    }

    .cards_about{
        gap: 3dvw;
    }

    .card_about{
        padding: calc(10.626%/2) 12dvw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .cards_founders article{
        width: 32.8%;
    }
    .timeline img{
        width: 40%;
    }
}