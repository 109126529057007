
.head {
    margin: 3dvh  5.55556dvw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    align-items: center;
    flex-wrap: wrap;
}
.link{
    display: contents;
}

.image_logo{
    width: auto;
    height: auto;
    max-width: 26.25dvh;
    min-width: 10dvh;
    max-height: 5.252dvh;
} 
@media screen and (max-width: 288px) {
}

@media screen and (min-width: 480px) {

}

@media screen and (min-width: 768px) {
    .image_logo{
        width: auto;
        height: auto;
        max-width: 20%;
        min-width: 8%;
        max-height: 8%;
    }
}

@media screen and (min-width: 1200px) {
    .head {
        margin: 4dvh 12dvw;
    }
}