main {
    display: flex;
    flex-direction: column;
}
.page {
    background-color: var(--white);
    padding: 6.66667dvw 5.55556dvw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: calc(6.66667dvw/2);
}

.space {
    background-color: transparent;
    color: var(--white);
    font-size: 1.7094rem;
    font-weight: var(--black);
    text-transform: uppercase;
    text-align: center;
    margin-right: 30px;
    margin-left: 30px;
}

.space h1{
    margin-top: 0;
}

.space img{
    width: 80%;
}

.cards {
    display: flex;
    width: 100%;
    height: auto;
    gap: 24px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.intro h2 {
    padding: 8%;
    background: linear-gradient(#0066ff, #9900D3);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: auto;
    font-weight: var(--bold);
}

.intro {
    position: relative;
    width: 100%;
    height: auto;
    border: double 0.2rem transparent;
    border-radius: 1.375rem;
    background-image: linear-gradient(white, white), linear-gradient(#0066ff, #9900D3);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: content-box, border-box;
}
.card_pages {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    margin: auto;
}

.card_pages img{
    position: relative;
    max-width:100%;
    max-height: 100%;
    border-radius: 20px;
}

.card_pages h1 {
    position: absolute;
    bottom:3%;
    left: 6%;
    color: white;
    font-size:40px;
    text-transform: uppercase;
    text-shadow: 0px 2px 4px black;
    margin: 0;
}

.image_sat {
    width: auto;
    height: auto;
    max-width: 300px;
    min-width: 100px;
}

.logomenu {
    width: auto;
    height: auto;
    max-width: 200px;
    min-width: 100px;
}

.warn:hover {
    background-color: #FFC107;
    color: #332701;
}

@media screen and (min-width: 480px) {
    .page {
        gap: 6.66667dvw;
    }
    .intro h2 {
        padding: 6%;
    }
    .card_pages{
        width: 47.1%;
    }
    .card_pages h1{
        font-size: 160%;
        bottom:6%;
        left: 9%;
    }
}

@media screen and (min-width: 768px) {
    .page {
        gap: calc(6.66667dvw/2);
    }
    .space img{
        width: 20%;
    }
    .intro h2 {
        padding: 4%;
    }
}

@media screen and (min-width: 1200px) {
    .page {
        padding: calc(6.66667dvw/2) 12dvw;
    }

    .cards {
        flex-direction: row;
    }

    .card_pages {
        width: 23%;
    }

    .card_pages h1{
        font-size: 115.3845%;
    }
    .space img{
        width: 20%;
    }
}