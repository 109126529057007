.menu_icon{
    width: auto;
    height: auto;
    max-width: 6dvh;
    min-width: 3.5dvh;
    padding: 0.625dvh;
}

.menu_button {
    width: auto;
    height: auto;
    display: flex;
    border: double 0.25dvh transparent;
    border-radius: 1.5dvh;
    background-image: url(../../images/noise_effect.png), linear-gradient(#000423, #000423), linear-gradient(#0066ff, #9900D3);
    background-origin: border-box;
    background-size: 900%;
    background-clip: content-box, content-box, border-box;
    color: var(--white);
}

@media screen and (min-width: 480px) {
    .menu_button {
        background-image: url(../../images/noise_effect.png), linear-gradient(#000423, #000423), linear-gradient(#0066ff, #9900D3);
      background-size: 600%;
    }
}