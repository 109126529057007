main {
    display: flex;
    flex-direction: column;
}
.page {
    background-color: var(--white);
    padding: 6.66667dvw 5.55556dvw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: calc(6.66667dvw/2);
    align-items: center;
}

.space {
    background-color: transparent;
    color: var(--white);
    font-size: 1.7094rem;
    font-weight: var(--black);
    text-align: center;
    margin-right: 30px;
    margin-left: 30px;
}

.head h1{
    margin-bottom: 2%;
}

.head p{
    font-size: 1rem;
    margin-top: 0;
}

.space h1{
    margin-top: 0;
}

.page h2{
    font-size: 1.25rem;
    width: 100%;
}

.page p{
    text-align: justify;
    font-size: 0.875rem;
    width: 100%;
}

.page ol{
    padding-left: 5.55556dvw;
}

.page ul{
    padding-left: 5.55556dvw;
}

.page li{
    text-align: justify;
}

.page article{
    border: solid 0.14rem var(--black);
    border-radius: 16px;
    padding: 6%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.update{
    display: flex;
    flex-direction: column;
    border-color: none;
    width: 64%;
    background: linear-gradient(180deg, #37C034 0%, #267E24 100%);
    border-radius: 12px;
    padding: 4% 4%;
}

.update p{
    color: var(--white);
    text-align: center;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .update{
        padding: 3% 1%;
        width: 44%;
    }
    .page {
        padding: calc(6.66667dvw/2) 12dvw;
    }
    .page article{
        padding: 3%;
    }
}