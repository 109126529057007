.footer {
    background-color: var(--black);
    background-image: url(../../images/noise_effect.png);
    background-size: 100%;
    color: white;
    text-align: center;
    width: 100%;
    position: relative;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
}
.footer p {
    color:#0066ff;
    font-size: 0.875rem;
    margin-top: 0px;
    margin-bottom: 0.75dvh;
}

.footerLogo img{
    width: auto;
    height: auto;
    max-width: 15.625dvh;
    min-width: 50px;
}

.footerLogo {
    margin: 3dvh;
}

.socialMedia {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2.75dvh;
    gap: 16px;
    flex-wrap: wrap;
}

.socialMedia svg {
    color: var(--white);
    width: auto;
    height: 4dvh;
}

.container{
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    flex-basis: 2%;
}

.container ul {
    text-align: left; 
    padding: 0px;
    margin-top:0px;
    margin-bottom: 0px;
    width: 122px;
}

.side2 {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    flex-basis: 2%;
}

.side2 ul:first-child{
    margin-bottom: 8px;
}

.container ul li {
    display:block;
    font-size: 0.75rem;
    margin-bottom: 1dvh;
    text-wrap: wrap;
    width: 100%;
}

.container ul li a{
    color: var(--white);
    text-decoration: none;
}

.container ul li:last-child{
    margin-bottom: 0;
}

@media screen and (max-width: 288px) {
    .container ul{
        width: 110px;
    }
    .footerLogo img{
        width: auto;
        height: auto;
        max-width: 48.614dvw;
        min-width: 50px;
    }
    .footerLogo {
        margin: 10dvw;
    }
}

@media screen and (min-width: 480px) {
    .container ul{
        width: 122px;
    }

    .side2 {
        flex-basis: auto;
    }
    .footer{
        background-image: url(../../images/noise_effect.png);
        background-size: 70%;
    }

}

@media screen and (min-width: 768px) {
    .container ul{
        width: 182px;
    }
    .footer{
        background-image: url(../../images/noise_effect.png);
        background-size: 50%;
    }

}

@media screen and (min-width: 1200px) {
    .container ul{
        width: 204px;
    }
    .footer{
        background-image: url(../../images/noise_effect.png);
        background-size: 30%;
    }

}
