nav a{
    margin: 0;
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: var(--blackFont);
}

nav a:hover{
    color: #999999;
}

.nav_btn {
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    visibility: visible;
    opacity: 1;
}


@media only screen and (max-width: 767px){
    nav {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 1.5rem;
        transition: 1.2s;
        transform: translateY(-100vh);
        z-index: 9999;
    }
    nav article {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 8.25dvh 5.5dvh;
        gap: 1.5rem;
        font-size: 2rem;
    }
    .responsive_nav {
        transform: none;
    }
    .nav_close_btn {
        position: absolute;
        top: 3dvh;
        right: 2.5dvh;
    }
    
}

@media screen and (max-width: 480px) {
    nav {
      background-image: url(../../images/noise_effect.png);
      background-color: var(--richBlack);
      background-size: 100%;
    }
}

@media screen and (min-width: 480px) {
    nav {
      background-image: url(../../images/noise_effect.png);
      background-color: var(--richBlack);
      background-size: 70%;
    }
}

@media screen and (min-width: 768px) {
    .nav_btn {
        visibility: hidden;
        opacity: 0;
    }

    nav {
        background: transparent;
    }
    
    nav a{
        margin: auto;
        margin-right: 1rem;
        flex-direction: row;
        align-items: center;
    }

    nav a:last-child{
        margin-right: 0;
    }
}