:root {
  --white: #fff;
  --black: #000;
  --richBlack: #000423;
  --brandeisBlue: #0066FF;
  --darkViolet: #9400D3;
  --selectiveYellow: #FFB800;
  --crimsonRed: #9E0000;

  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
  --blackFont: 900;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000423;
  background-image: url(./images/noise_effect.png);
  background-size: 100%;
}

html {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 480px) {
  body {
    background-image: url(./images/noise_effect.png);
    background-size: 70%;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 20px;
  }
  body {
    background-image: url(./images/noise_effect.png);
    background-size: 50%;
  }
}

@media screen and (min-width: 992px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  body {
    background-image: url(./images/noise_effect.png);
    background-size: 30%;
  }
}